import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-house-pitch',
  templateUrl: './house-pitch.component.html',
  styleUrls: ['./house-pitch.component.scss']
})
export class HousePitchComponent implements OnInit {

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() pitchStep;

  @Input() roofPitch;


  constructor(private  toastr: ToastrService) {

  }

  ngOnInit(): void {
  }

  selectPitch(value): void {
    this.roofPitch = value;
  }

  pitchCheck() {
    if (this.roofPitch > 90 || this.roofPitch < 0) {
       this.toastr.warning('Pitch can not be greater than 90 and less than 0');
       if (this.roofPitch < 0) {
         this.roofPitch = 0;
         return false;
       }
       if (this.roofPitch > 90) {
        this.roofPitch = 90;
        return false;
      }
       return false;
    }
  }


  public submitForm(): void {
    this.onSubmit.emit({
      value: this.roofPitch
    });
  }

}
