import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HomeService} from '../home/home.service';
import {Constants} from '../app.constant';
import {ToastrService} from 'ngx-toastr';
import {HouseConsumption} from '../house-consumption/house-consumption.model';
import {ConsumptionModel} from './Consumption.model';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Quotev3Component} from '../quotev3/quotev3.component';
import {ResultDataService, ResultService} from '../result/result.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-consumptionv3',
  templateUrl: './consumptionv3.component.html',
  styleUrls: ['./consumptionv3.component.scss']
})
export class Consumptionv3Component implements OnInit {
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() public consumption: ConsumptionModel;
  email;
  stateList = [];
  tariffList = [];
  electricSuppliers = [];
  selectedState;
  leadId;
  electricId;
  getSupplier = false;
  rdata;
  data;
  showLoader = false;
  ePrice;
  nm;
  pvTariff;
  ePriceObj: any;
  nmObj: any;
  pvTariffObj;
  constructor( private homeService: HomeService,
               private appConstants: Constants,
               private  toastr: ToastrService,
               private modalService: BsModalService,
               private bsModalRef: BsModalRef,
               private  resultService: ResultService,
               private router: Router,
               private resultDataService: ResultDataService ) {  this.appConstants.email.subscribe(value => {
                                                                  this.email = value as string; });
                                                                 this.appConstants.leadId.subscribe( value => {
                                                                  this.leadId = value as string; });
                                                                 this.consumption = new ConsumptionModel();
                                                                 this.getSupplier = false;
                                                    // this.consumption.numPeople = 1;
                                                  }

  ngOnInit(): void { this.getState(); }
  // this is the first api that runs that will give us the state list
  getState() {
    this.homeService.getState(this.leadId, 'eprice').subscribe(res => {
      const response = res as any;
      for (const state of response.states ) {
        this.stateList.push(state);
      }
      this.ePriceObj = {
        ...response.eprice
      };
      this.nmObj = {
        ...response.nm
      };
      this.pvTariffObj = {
        ...response.pvtariff
      };
      console.log(this.ePriceObj);
      this.stateList.sort();
    }, error => {
      this.toastr.error('State data not found', 'Oops');
    });
  }

  yearlyEnergyConsumption() {

      this.consumption.YearlyElecRequired = Math.round((Math.round(1097 * this.consumption.numPeople + 2614.1) * 0.6) / 12);

    // else {
    //   this.consumption.YearlyElecRequired = Math.round((Math.round(1097 * this.consumption.numPeople + 2614.1) * 0.6) / 12);
    // }
  }


  updateContactInformation() {
    const initialState = {
      title: 'Contact form',
      class: 'modal-xl'
    };
    this.bsModalRef = this.modalService.show(Quotev3Component, {initialState});


    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.onClose = (myData) => {
      // myData.data.customercontact.lead_id = this.model.leadId;
      this.homeService.updateContactInformations(myData.data).subscribe(reply => {
        const response = reply as any;
        this.bsModalRef.hide();
        this.toastr.success('Quotation upload successfully');
      }, error => {
        this.toastr.warning('Contact Information not updated Please try again', 'Oops');
      });
    };
  }

  uploadYearlyConsumption() {
    this.homeService.postStateTariffDetails(this.leadId, 'upload', {
      eprice: this.ePrice,
      state: this.selectedState,
      ebillmonthly: this.consumption.YearlyElecRequired,
      nm: this.nm,

    }).subscribe( res => {
      const response = res as any;
      console.log(response);
      this.getResult();
    }, error => {
      this.toastr.warning('Yearly Electric not updated Please try again', 'Oops');
    });
  }

  changeStateTariffDetails() {
    localStorage.setItem('state', this.selectedState);
    this.ePrice = this.ePriceObj[this.selectedState];
    this.nm = this.nmObj[this.selectedState];
    this.pvTariff = this.pvTariffObj[this.selectedState];
    this.electricId = 0;
    console.log(this.nm, this.pvTariff);
  }

  getResult() {
    this.resultService.getResult().subscribe(res => {
      this.data = res as any;
      this.router.navigate(['resultv3']);
      if (this.data.status) {
        this.resultDataService.setResult(this.data);
      }
    }, error => {
      this.toastr.warning('Something went wrong try again', 'Oops');
    });
  }
  submitForm() {
    // this.getResult();
    if (this.consumption.numPeople && this.selectedState) {
    this.uploadYearlyConsumption();
    this.updateContactInformation();
    this.onSubmit.emit({
      value: 'Average Consumption'
      });
  } else {
      this.toastr.warning('Please enter the details first!');
    }
  }



}
