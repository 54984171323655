<div class="container-fluid ">
  <div class="row wrapper-row remove-extra-space">
    <div class="result-bar d-flex align-items-center" style="height:100% ;width: 100%; background-color: #E5E9EC"
         *ngIf="showLoader">
      <div class="container-fluid">
        <div class="row mb-5">
          <div class="col-12  ">
            <p class="fetch-result text-center">{{'Preparing-Results' | translate}}</p>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12">
            <div class="progress" style="height: 37px">
              <div class="progress-bar progress-bar-striped bg-success progress-bar-animated" role="progressbar"
                   style="width: 100%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-center result-content">
              {{'content' | translate}}
              {{'content1' | translate}}
              {{'content2' | translate}}
              {{'content3' | translate}}

            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-7 mobile-map" id="mobile-map">
      <div class="map-wrapper" *ngIf="!showLoader">
        <div id="viewDivM"></div>

        <div class="esri-widget sketch" id="sketchPanelM">
          <div id="searchM" style="display:inline-block; position: relative; vertical-align: text-bottom "></div>
          <div id="mapButtonM" style="max-height: 100%;min-width: 130px; display: inline-block; position: relative">
            <button class="button-sketch esri-button sketch button-1" data-type="polygon" id="buildingM"
                    style=" text-transform: uppercase; margin-left: 0px; margin-right: 2px">
              <i class="material-icons sketch" id="createIconM">create</i>
            </button>
            <button class="button-sketch esri-button sketch button-1" id="deleteM"
                    style=" text-transform: uppercase; margin-left: 0px; margin-right: 2px">
              <i class="material-icons sketch" id="deleteIconM">delete</i>
            </button>
            <button class="button-sketch esri-button sketch button-1" id="infoM"
                    style=" text-transform: uppercase; margin-left: 0px; margin-right: 2px">
              <i class="material-icons sketch" id="infoIconM">info</i>
            </button>

          </div>
          <div style="display: grid" id="areaOnMapM">
            <label class="label-class" for="selectedAreaM">{{'Selected_Area' | translate}} (m2)</label>
            <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label params">
              <input class="mdc-text-field__input mdc-text-field--disabled" disabled id="selectedAreaM" type="text"
                     value="0">
              <div class="mdc-notched-outline">
                <div class="mdc-notched-outline__leading"></div>
                <div class="mdc-notched-outline__notch"></div>
                <div class="mdc-notched-outline__trailing"></div>
              </div>
            </div>
          </div>
        </div>

        <div id="infopopupM" class="popup">
          <div class="popup-material">
            <i class="material-icons top-right button" id="closepopupM">clear</i>
            <table style="width:100%">
              <tr class="tr-info">
                <td><span>{{'Map.pencilInfo' | translate}}</span></td>
                <td><i class="material-icons sketch">create</i></td>
              </tr>
              <tr class="tr-info">
                <td><span>{{'Map.deleteInfo' | translate}}</span></td>
                <td><i class="material-icons sketch">delete</i></td>
              </tr>

            </table>
          </div>
        </div>

      </div>
    </div>
    <div class="col-md-5 scroll" *ngIf="!showLoader">
      <div class="form-wrapper card">

        <div class="steps-container">
          <div *ngFor="let step of formSteps; let i = index">
            <div *ngIf="step.isVisible">
              <div class="step-nav-link" *ngIf="activeStep!=i">
                <div class="row">
                  <div class="col-4">
                    <div class="step-label"
                         style="color: #D5D5D5; font-family: 'poppins';">{{step.label | translate}}</div>
                  </div>
                  <div class="col-8">
                    <div class="step-right">
                      <div class="row ">
                        <div class="col-7 step-label">
                          <div *ngIf="step.key=='house-roof-area' && model.roofArea">
                            {{model.roofArea}} m<sup>2</sup>
                          </div>
                          <div *ngIf="step.key=='house-roof-type' && model.roofArea">
                            <div class="roof-type-nav-icon">
                              <div class="roof-type-nav-icon" [innerHTML]="model?.roofType?.iconSvg"></div>
                            </div>
                          </div>
                          <div *ngIf="step.key =='house-pitch' && model.roofPitch !== null">
                            {{model.roofPitch}} &#176;
                          </div>
                          <div *ngIf="step.key == 'house-hot-water' && model.hotWaterType">
                            {{ waterHeaters[model.hotWaterType] | translate }}
                          </div>
                          <div *ngIf="step.key == 'house-heater' && model.heaterType">
                            {{ houseHeaters[model.heaterType] | translate }}
                          </div>
                          <div *ngIf="step.key == 'house-alignment' && model.alignment !== null">
                            {{model.alignment}}
                          </div>
                          <div *ngIf="step.key == 'house-consumption' && model.consumption?.numPeople !== null">
                            {{model.consumption.numPeople}},{{model.consumption.YearlyElecRequired}}
                          </div>

                        </div>
                        <div class="col-5">
                          <b><a *ngIf="step.isEditable" (click)="showStep(i)">{{'To-change' | translate}}</a></b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div [collapse]="activeStep!=i" [isAnimated]="true">
                <div class="step-form-container " *ngIf="model.leadId">
                  <div *ngIf="step.key=='house-roof-area'">
                    <app-house-roof-area [roofArea]="model.roofArea"
                                         (onSubmit)="uploadMapArea(i, $event)" > </app-house-roof-area>
                  </div>
                  <div *ngIf="step.key=='house-roof-type'">
                    <app-house-roof-type (onSubmit)="onSubmit(i, $event)" ></app-house-roof-type>
                  </div>
                  <div *ngIf="step.key=='house-pitch'">
                    <app-house-pitch (onSubmit)="onSubmit(i, $event)" ></app-house-pitch>
                  </div>
                  <div *ngIf="step.key=='house-alignment'">
                    <app-house-alignment *ngIf="model && model?.roofType && model?.roofType?.key"
                                         [roofType]="model?.roofType?.key"
                                         (onSubmit)="onSubmit(i, $event)"></app-house-alignment>
                  </div>
                  <div *ngIf="step.key=='house-priority'">
                    <app-house-priority [leadId]="model.leadId" (onSubmit)="onSubmit(i, $event)"></app-house-priority>
                  </div>
                  <div *ngIf="step.key=='house-hot-water'">
                    <app-house-hot-water (onSubmit)="onSubmit(i, $event)"></app-house-hot-water>
                  </div>
                  <div *ngIf="step.key=='house-heater'">
                    <app-house-heater (onSubmit)="onSubmit(i, $event)"></app-house-heater>
                  </div>
                  <div *ngIf="step.key=='house-air-conditioning'">
                    <app-home-air-condition (onSubmit)="onSubmit(i, $event)"></app-home-air-condition>
                  </div>
                  <div *ngIf="step.key=='house-consumption'">
                    <app-house-consumption [leadId]="model.leadId" [airCondition]="model.airCondition" [yearly]="yearly"
                                           (extraInput1)="onSubmit(i, $event)" (skip)="skip1($event)"
                                           (onSubmit)="onSubmit(i, $event)"></app-house-consumption>
                  </div>
                  <div *ngIf="step.key=='house-energy'">
                    <app-house-energy [leadId]="model.leadId" (onSubmit)="onSubmit(i, $event)"></app-house-energy>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7 desktop-map">
      <div class="map-wrapper" *ngIf="!showLoader">
        <div id="viewDiv"></div>

        <div class="esri-widget sketch" id="sketchPanel">
          <div id="search" style="display:inline-block; position: relative; vertical-align: text-bottom "></div>
          <div style="max-height: 100%;min-width: 130px; display: inline-block; position: relative">
            <button class="button-sketch esri-button sketch button-1" data-type="polygon" id="building"
                    style=" text-transform: uppercase; margin-left: 0px; margin-right: 2px">
              <i class="material-icons sketch" id="createIcon">create</i>
            </button>
            <button class="button-sketch esri-button sketch button-1" id="delete"
                    style=" text-transform: uppercase; margin-left: 0px; margin-right: 2px">
              <i class="material-icons sketch" id="deleteIcon">delete</i>
            </button>
            <button class="button-sketch esri-button sketch button-1" id="info"
                    style=" text-transform: uppercase; margin-left: 0px; margin-right: 2px">
              <i class="material-icons sketch" id="infoIcon">info</i>
            </button>

          </div>
          <div style="display: flex; margin-top: 5px">
            <label class="label-class" for="selectedArea">{{'Selected_Area' | translate}} (m2)</label>
            <div class="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label params">
              <input class="mdc-text-field__input mdc-text-field--disabled" style="height: 30px;" disabled id="selectedArea" type="text"
                     value="0">
              <div class="mdc-notched-outline">
                <div class="mdc-notched-outline__leading"></div>
                <div class="mdc-notched-outline__notch"></div>
                <div class="mdc-notched-outline__trailing"></div>
              </div>
            </div>
          </div>
        </div>

        <div id="infopopup" class="popup">
          <div class="popup-material">
            <i class="material-icons top-right button" id="closepopup">clear</i>
            <table style="width:100%">
              <tr class="tr-info">
                <td><span>{{'Map.pencilInfo' | translate}}</span></td>
                <td><i class="material-icons sketch">create</i></td>
              </tr>
              <tr class="tr-info">
                <td><span>{{'Map.deleteInfo' | translate}}</span></td>
                <td><i class="material-icons sketch">delete</i></td>
              </tr>

            </table>
          </div>
        </div>


      </div>
    </div>
  </div>

</div>
