<section class="cont">
  <div class="container justify-content-center text-center">
    <div class="row mt-5 pt-5">
      <div class="col "><span class="dtext">{{'address.header'| translate}}</span></div>
    </div>
    <div class="row mt-4 mb-5">
      <div class="col"><span class="heading">{{'address.heading' | translate}}</span></div>
    </div>
    <div class="row mt-4 py-3 px-5">
      <div class="col"><span class="ptext">{{'address.content' | translate}}</span>
      </div>
    </div>

  </div>


  <div class="search input-group mt-4 mb-3">
    <input type="text" class=" form-control" style="height: 60px" [options]="options"
           (onAddressChange)="AddressChange($event)" ngx-google-places-autocomplete placeholder="{{'address.placeH' | translate}}"
           aria-label="Recipient's username" aria-describedby="basic-addon2">
    <div class="input-group-append" style="height: 60px">
      <span class="input-group-text" id="basic-addon2"><img style="height: 50px; border-radius: 3px"
                                                            src="./assets/refresh.png" (click)="submitForm()"></span>
    </div>
  </div>
  <div class="mb-1">
    <img style="width: 100%;" src="assets/bgimg.png"  >
  </div>
</section>




