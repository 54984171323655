<section>
<div class="justify-content-center text-center">
<div class="row mt-5 p-3 mb-2">
  <div class="col">
    <span class="dtext">{{'Consumption.header' | translate}}</span>
  </div>
</div>
<div class="row heading my-3 pb-2">
  <div class="col">
    <span class="htext">{{'Consumption.question1' | translate}}</span>
  </div>
</div>
  <div class="row mt-4 ">
    <div class="col">
      <input class="form-control text-center searchbar w-25" type="text" [(ngModel)]="consumption.numPeople" (ngModelChange)="yearlyEnergyConsumption()" placeholder="{{'Consumption.num-of-people-placeholder' | translate}}" aria-label="Search">
    </div>
  </div>
  <div class="row heading my-3 py-2">
    <div class="col">
      <span class="htext">{{'Consumption.question2' | translate}}</span>
    </div>
  </div>
  <div class="row mt-4 ">
    <div class="col">
      <input class="form-control text-center searchbar w-25" [(ngModel)]="consumption.YearlyElecRequired" [ngModelOptions]="{standalone: true}" type="text" placeholder="{{'Consumption.yearly-consumption' | translate}}" aria-label="Search">
    </div>
  </div>
  <div class="row heading mt-3  py-2">
    <div class="col">
      <span class="htext">{{'Consumption.statequestion' | translate}}</span>
    </div>
  </div>
  <div class="row mt-4 ">
    <div class="col d-flex justify-content-center">
      <select name="electricId" id="state" class="w-25 form-control text-center searchbar " (change)="changeStateTariffDetails()" [ngModelOptions]="{standalone: true}"  [(ngModel)]="selectedState" class="form-control">
        <option [ngValue]="undefined"  hidden selected>{{'Consumption.state' | translate}}</option>
<!--        <option  *ngIf="!stateList" [value]="0" hidden >select state</option>-->
        <option *ngFor="let state of stateList" [value]="state">{{state}}</option>
      </select>
    </div>
  </div>
<!--  <div class="row heading mt-3  py-2">-->
<!--    <div class="col">-->
<!--      <span class="htext">{{'Consumption.tariffquestion' | translate}}</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row mt-4 ">-->
<!--    <div class="col">-->
<!--      <select id="tariff" class="form-control text-center searchbar w-25" type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="selectedTariff" placeholder="Select Energy Supplier" >-->
<!--        <option [ngValue]="undefined" hidden></option>-->
<!--        <option *ngFor="let tariff of tariffList" [value]="tariff">{{tariff}}</option>-->
<!--      </select>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row heading mt-3  py-2">-->
<!--    <div class="col">-->
<!--      <span class="htext">{{'Consumption.supplierquestion' | translate}}</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row mt-4 ">-->
<!--    <div class="col">-->
<!--      <select id="electricSupp" class="form-control text-center searchbar w-25" [ngModelOptions]="{standalone: true}" (change)="uploadSupplierTariff()"  [(ngModel)]="selectedSupplier" type="text" placeholder="Select Energy Supplier" >-->
<!--        <option [ngValue]="undefined" hidden></option>-->
<!--        <option *ngFor="let electric of electricSuppliers" [value]="electric">{{ electric }}</option>-->
<!--      </select>-->
<!--    </div>-->
<!--  </div>-->

  <div class="mt-3">
    <button type="button" style="font-size: 17px" class="btn btn-secondary" (click)="submitForm()">{{'Continue' | translate}}</button>
  </div>

</div>
  <div>
    <img style="width: 100%" src="assets/bgimg.png" >
  </div>
</section>
